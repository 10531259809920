import { Injectable, inject, signal } from '@angular/core';
import { Observable, take, tap } from 'rxjs';

import { BackendEventsService } from '@services/backend-events.service';

import { SUCCESS, TranslateCameraParam } from '../types/jsonrpc.interface';

/**
 * MoveCameraService предоставляет методы для управления перемещением камеры.
 *
 * Этот сервис обеспечивает взаимодействие с бэкендом для выполнения команды
 * перемещения камеры и отслеживает состояние процесса перемещения.
 */
@Injectable({ providedIn: 'root' })
export class MoveCameraService {
  /**
   * Переменная isTranslating используется для отслеживания состояния, указывающего,
   * происходит ли в данный момент процесс перевода.
   * Значение является булевым и устанавливается в false по умолчанию.
   */
  isMovingCamera = signal(false);

  /**
   * #backendEventsService - переменная, содержащая экземпляр BackendEventsService,
   * используемого для обработки событий на стороне сервера.
   */
  #backendEventsService = inject(BackendEventsService);

  /**
   * Перемещает камеру в заданное положение, используя предоставленные параметры.
   * Метод возвращает Observable, который выполняется при успешной обработке.
   *
   * @param {TranslateCameraParam} params Параметры для перемещения камеры.
   * @return {Observable<SUCCESS>} Observable, сигнализирующий о завершении операции успехом.
   */
  translateCamera(params: TranslateCameraParam): Observable<SUCCESS> {
    this.isMovingCamera.set(true);

    return this.#backendEventsService.translateCamera(params).pipe(
      take(1),
      tap(() => this.isMovingCamera.set(false)),
    );
  }
}
